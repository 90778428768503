import * as React from "react";
import {
  Wrapper,
  ListWrapper,
  LinkWrapper,
  ButtonWrapper,
  FalseLinkWrapper,
  Divider,
  SubListWrapper,
  ListHeader,
  ColumnWrapper,
  NavButton,
  MenuButton
} from "./Artistes.module.css";
import { GrayTitle } from "./../../../css/Title.module.css";
import Button from "./../../../components/Button";
import CoolTabs from 'react-cool-tabs';


const artists = [

  {
    path: "ana-monso",
    name: "ANA MONSÓ",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path: "mathias-bensimon",
    name: "MATHIAS BENSIMON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  ,
  {
    path:"",
    name: "CLÉMENCE APPIE GBONON",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "HAKIM SAHIRI",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "FÉLIX TABURET & HANNAH BECQUANTE",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },
  {
    path:"",
    name: "DJABRIL BOUKHENAÏSSI",
    description: "Description",
    photos: ["path", "path", "path"],
    works: ["Works", "Works", "Works"],
    videos: ["Videos", "Videos", "Videos"],
    order: 0,
  },


];

const Artistes = () => {
  return (
    <div className={Wrapper}>
      <h1 className={GrayTitle}>ARTISTES</h1>
      <br />
      <div className={ListWrapper}>
       <div className={ColumnWrapper}>
          {artists.slice(0, 2).map((artist) => (
            artist.path ? 
            <a href={"/en-projects/artistes/" + artist.path} className={LinkWrapper}>
              {artist.name}
            </a>
            : <p className={FalseLinkWrapper}>{artist.name}</p>
          ))}
        </div>
        <div className={ColumnWrapper}>
          {artists.slice(2, 7).map((artist) => (
            artist.path ? 
            <a href={"/en-projects/artistes/" + artist.path} className={LinkWrapper}>
              {artist.name}
            </a>
            : <p className={FalseLinkWrapper}>{artist.name}</p>
          ))}
            <br />
        </div>
      </div>
        
      <div className={ButtonWrapper}>
        <Button
          alt=""
          onClick={() => {
            if (typeof window !== "undefined") {
              window.location.href = "/fr/contactez-nous";
            }
          }}
        >
          Contactez-nous
        </Button>
      </div>
    </div>
  );
};

export default Artistes;
