// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Artistes-module--ButtonWrapper --Chpr-";
export var ColumnWrapper = "Artistes-module--ColumnWrapper--0DXAJ";
export var FalseLinkWrapper = "Artistes-module--FalseLinkWrapper--wjisH";
export var LinkWrapper = "Artistes-module--LinkWrapper--7ESDe";
export var ListHeader = "Artistes-module--ListHeader--5SAdZ";
export var ListWrapper = "Artistes-module--ListWrapper--5ITHp";
export var MenuButton = "Artistes-module--MenuButton--A4mBB";
export var NavButton = "Artistes-module--NavButton--3le48";
export var SubListWrapper = "Artistes-module--SubListWrapper--TKRpn";
export var Wrapper = "Artistes-module--Wrapper--GVD9D";